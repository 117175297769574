import React from 'react';

export type TailwindDefaultColors =
  | 'grey'
  | 'red'
  | 'orange'
  | 'yellow'
  | 'green'
  | 'teal'
  | 'blue'
  | 'indigo'
  | 'purple'
  | 'pink';
export interface TailwindButtonProps {
  onClick?: () => void;
  htmlType?: 'submit' | 'button';
  children?: React.ReactNode;
  size?: 'small' | 'medium' | 'large';
  style?: React.CSSProperties;
  loading?: boolean;
  disabled?: boolean;
  color?: TailwindDefaultColors;
  colorWeight?: 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;
}

const getClassNames = (props: {
  loading: boolean;
  disabled: boolean;
  color: TailwindButtonProps['color'];
  colorWeight: TailwindButtonProps['colorWeight'];
}) => {
  let classNames =
    'w-full text-white font-bold py-2 px-4 border-b-4 rounded shadow-md';

  let color: TailwindDefaultColors = 'blue';
  let weight = {
    base: 500,
    border: 700,
    hover: 400,
    borderHover: 500,
  };

  if (props.colorWeight) {
    weight = {
      ...weight,
      base: props.colorWeight,
      borderHover: props.colorWeight,
    };
    if (props.colorWeight <= 700) {
      weight = {
        ...weight,
        border: props.colorWeight + 200,
        hover: props.colorWeight - 100,
      };
    } else {
      weight = {
        ...weight,
        border: Math.min(props.colorWeight + 200, 900),
        hover: props.colorWeight - 100,
      };
    }
  }
  if (props.color) {
    color = props.color;
  }

  classNames = `${classNames} border-${color}-${weight.border} bg-${color}-${
    weight.base
  } hover:bg-${color}-${weight.hover} hover:border-${color}-${weight.borderHover}`;

  if (props.loading) {
    classNames = `${classNames} spinner`;
  }
  if (props.disabled) {
    classNames = `${classNames} opacity-50 cursor-not-allowed`;
  }
  /* purgecss hack :sadface:
    border-green-700 bg-green-500   hover:bg-green-400 hover:border-green-500
    border-green-600 bg-green-400   hover:bg-green-300 hover:border-green-400
    border-orange-700 bg-orange-500   hover:bg-orange-400 hover:border-orange-500
    border-orange-600 bg-orange-400   hover:bg-orange-300 hover:border-orange-400
    border-blue-700 bg-blue-500   hover:bg-blue-400 hover:border-blue-500
    border-blue-600 bg-blue-400   hover:bg-blue-300 hover:border-blue-400
  */
  return classNames;
};

const ButtonTW = (props: TailwindButtonProps) => {
  const {
    onClick,
    children,
    loading = false,
    disabled = false,
    htmlType = 'button',
    style,
    color = "green",
    colorWeight = 400,
  } = props;
  return (
    <button
      style={style}
      onClick={onClick ? onClick : undefined}
      type={htmlType}
      className={getClassNames({ loading, disabled, colorWeight, color })}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
export default ButtonTW;
