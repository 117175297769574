import * as yup from 'yup';

export const phoneRegExp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
export const invalidEmail = 'Email must be a valid email';

export const defaultLeadSchema = yup.object().shape({
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  phone: yup
    .string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .required(),
  email: yup
    .string()
    .email(invalidEmail)
    .required('Please enter your email'),
  state: yup
    .string()
    .matches(
      /^(?:A[KLRZ]|C[AOT]|D[CE]|FL|GA|HI|I[ADLN]|K[SY]|LA|M[ADEINOST]|N[CDEHJMVY]|O[HKR]|PA|RI|S[CD]|T[NX]|UT|V[AT]|W[AIVY])*$/
    ),
  loans_type: yup
    .string()
    .oneOf([
      'Federal Student Loan Debt',
      'Private Student Loan Debt',
      'Federal & Private Student Loan Debt',
    ]),
  debt_amount: yup
    .string()
    .oneOf([
      'Under $9,999',
      '$10,000 - $14,999',
      '$15,000 - $29,999',
      '$30,000 - $49,999',
      '$50,000 - $99,999',
      '$100,000 or more',
    ]),
});
export const loansTypeOptions = Array.from(
  (defaultLeadSchema as any).fields.loans_type._whitelist.list
).map(item => ({
  label: item,
  value: item,
}));
export const debtAmountOptions = Array.from(
  (defaultLeadSchema as any).fields.debt_amount._whitelist.list
).map(item => ({
  label: item,
  value: item,
}));
