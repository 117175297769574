const defaultParams = [
  'affiliate_id',
  'sub_id',
  'utm_term',
  'utm_campaign',
  'utm_content',
  'utm_source',
  'utm_keyword',
  'utm_medium',
  'utm_context',
  'utm_panel',
  'utm_adpos',
  'utm_device',
  'utm_devicemodel',
  'utm_network',
  'utm_matchtype',
  'utm_ad',
  'cpAFID',
  'cpSID',
  'clickID',
];

type SearchObject = {
  [key: string]: any[];
};

export const mapSearchToTracking = (
  search: SearchObject,
  tracking = defaultParams
) => {
  if (!search) {
    return;
  }
  const result = {} as SearchObject;

  for (const key of Object.keys(search)) {
    if (tracking.includes(key)) {
      result[key] = search[key];
    }
  }

  return result;
};
/* expects location.search to be passed in */
export const parseLocationSearch = (search: string) => {
  const result = {} as any;
  const params = search.replace(/(^\?)/, '').split('&');
  for (const elem of params) {
    const pair = elem.split('=');
    result[pair[0]] = decodeURIComponent(pair[1]);
  }
  return result;
};
