export const formTracking = () => {
  if (!document.querySelectorAll) {
    return;
  }

  window.addEventListener('beforeunload', function(e) {
    window.dataLayer = window.dataLayer || [];

    findUnsubmittedForms().forEach(function(form) {
      window.dataLayer.push({
        event: 'formAbandonment',
        eventCategory: 'Form Abandonment',
        eventAction: form.name + ': ' + form.history.join(' > '),
      });
    });
  });

  const history = {};

  document.addEventListener('change', function(e) {
    const target = e.target;
    if (target) {
      let inputName = target.getAttribute('name');
      if (!inputName) {
        inputName = 'UNSET_INPUT_NAME';
        console.warn('Form inputs should have a name attribute');
      }
      const form = target.form;
      if (form && inputName) {
        const formName = form.getAttribute('name');
        if (!formName) {
          console.warn('Forms need a name in order to be tracked!');
          return;
        }
        if (!history[formName]) {
          history[formName] = [];
        }
        if (history[formName].slice(-1) !== inputName) {
          history[formName].push(inputName);
        }
      }
    }
  });

  function findUnsubmittedForms() {
    return Object.keys(history)
      .filter(hasNoFormSubmitEvent(window.dataLayer))
      .map(name => ({
        name,
        history: history[name] || [],
      }))
      .filter(form => form.history.length > 0);
  }

  function hasNoFormSubmitEvent(dataLayer) {
    return function(name) {
      return (
        dataLayer
          .filter(isFormSubmitEvent)
          .map(getFormName)
          .indexOf(name) === -1
      );
    };
  }

  function isFormSubmitEvent(e) {
    return e.event === 'gtm.formSubmit';
  }

  function getFormName(e) {
    return e['gtm.element'].name;
  }
};

export default formTracking;
