export const ZIPLINE_BASE_URL = 'https://in.ziplinelms.com/';
export const PRIVATE_LOANS_ZIPLINE_KEY = '856883ea-9df1-4ca4-b58a-4c2628b27f2b';
export const BRIGHT_HORIZONS_ZIPLINE_KEY =
  '3439be5a-893a-4697-a8aa-05b51e71421e';
export const GENERAL_ZIPLINE_KEY = 'b400b099-6e76-41df-894b-4553e28d274b'; // docupop google - irenetestsl = 9d040849-724c-408c-9543-6d632097a2ae

export const GENERAL_THANK_YOU_PAGE = '/thank-you';
export const GENERAL_PRIVATE_THANK_YOU = '/private-thank-you';
export const GENERAL_THANK_YOU_CS_OFFER_PAGE = '/cs-thank-you';
export const BRIGHT_HORIZONS_THANK_YOU_PAGE = '/thank-you-bhf';
