import React, { useState } from 'react';
import { Field, ErrorMessage, withFormik, FormikProps } from 'formik';
import { defaultLeadSchema } from '../utils/schema';
import states from '../utils/states';
import ButtonTW, { TailwindButtonProps } from './ButtonTW';

export type LeadFormValues = {
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  state: string;
  loans_type:
    | 'Federal Student Loan Debt'
    | 'Private Student Loan Debt'
    | 'Federal & Private Student Loan Debt';
  debt_amount: string;
};

interface ErrorMap {
  [key: string]: string;
}

type Props = {
  onSubmit: (values: LeadFormValues) => Promise<any>;
  initialValues?: LeadFormValues;
  buttonText?: string;
  buttonStyles?: React.CSSProperties;
  buttonColor?: TailwindButtonProps['color'];
  buttonColorWeight?: TailwindButtonProps['colorWeight'];
};

const C: React.FC<Props & FormikProps<LeadFormValues> & any> = props => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    buttonText,
    buttonStyles,
    buttonColor,
    buttonColorWeight,
  } = props;

  return (
    <form name="lead-form-ribbon-1-step" onSubmit={handleSubmit}>
      <div className="flex mb-4">
        <div className="flex flex-wrap -mx-3 mb-2 mt-8 mb-24">
          <div className="w-full lg:w-1/6 px-3 mb-6 md:mb-0">
            <label className="block uppercase tracking-wide text-blue-900 text-xs font-bold mb-2">
              First Name
            </label>
            <Field
              type="text"
              name="first_name"
              // placeholder="First Name"
              className="appearance-none block w-full bg-gray-200 text-blue-900 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 shadow-md"
            />
            <ErrorMessage
              name="first_name"
              component="div"
              className="text-red-800 mt-2"
            />
          </div>
          <div className="w-full lg:w-1/6 px-3 mb-6 md:mb-0">
            <label className="block uppercase tracking-wide text-blue-900 text-xs font-bold mb-2">
              Last Name
            </label>
            <Field
              type="text"
              name="last_name"
              // placeholder="Last Name"
              className="appearance-none block w-full bg-gray-200 text-blue-900 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 shadow-md"
            />
            <ErrorMessage
              name="last_name"
              component="div"
              className="text-red-800 mt-2"
            />
          </div>
          <div className="w-full lg:w-1/6 px-3 mb-6 md:mb-0">
            <label className="block uppercase tracking-wide text-blue-900 text-xs font-bold mb-2">
              Phone
            </label>
            <Field
              type="text"
              name="phone"
              placeholder=""
              required
              className="appearance-none block w-full bg-gray-200 text-blue-900 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 shadow-md"
            />
            <ErrorMessage
              name="phone"
              component="div"
              className="text-red-800 mt-2"
            />
          </div>
          <div className="w-full lg:w-1/6 px-3 mb-6 md:mb-0">
            <label className="block uppercase tracking-wide text-blue-900 text-xs font-bold mb-2">
              Email
            </label>
            <Field
              type="text"
              name="email"
              placeholder=""
              required
              className="appearance-none block w-full bg-gray-200 text-blue-900 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 shadow-md"
            />
            <ErrorMessage
              name="email"
              component="div"
              className="text-red-800 mt-2"
            />
          </div>
          <div className="w-full lg:w-1/6 px-3 mb-6 md:mb-0">
            <label className="block uppercase tracking-wide text-blue-900 text-xs font-bold mb-2">
              State
            </label>
            <div className="relative">
              <Field
                component="select"
                name="state"
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-blue-900 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 shadow-md"
                required
              >
                <option key="none">Select your state</option>
                {states.map((state, index) => (
                  <option value={state.value} key={index}>
                    {state.label}
                  </option>
                ))}
              </Field>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-blue-900">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/6 px-3 lg:py-8 sm:py-6 mb-6 md:mb-0 lg:mt-26">
            <ButtonTW
              htmlType="submit"
              disabled={isSubmitting}
              loading={isSubmitting}
              style={buttonStyles}
              color={buttonColor}
              colorWeight={buttonColorWeight}
            >
              {buttonText ? buttonText : 'Get Started'}
            </ButtonTW>
          </div>
        </div>
      </div>
    </form>
  );
};

export default withFormik<Props, LeadFormValues>({
  validationSchema: defaultLeadSchema,
  validateOnBlur: true,
  enableReinitialize: true,
  mapPropsToValues: props => props.initialValues as any,
  handleSubmit: async (values, { props, setErrors, setSubmitting }) => {
    const errors = await props.onSubmit(values);
    setSubmitting(false);
    if (errors) {
      setErrors(errors);
    }
  },
})(C);
